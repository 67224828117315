.view-screen {
  margin-top: 10px !important;
}

.view-screen #post-preview-canvas-div.offline {
  background-color: black;
}

.view-screen #post-preview-canvas-div .offlineOverlayLabel {
  position: absolute;
  top: 50%; /* Move the element down by 50% of the parent height */
  left: 50%; /* Move the element right by 50% of the parent width */
  transform: translate(-50%, -50%); /* Center the element using negative margins */
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  pointer-events: none;
  /*background-color: var(--ion-color-medium);*/
}


.view-screen #post-preview-canvas-div .liveOverlayLabel {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  pointer-events: none;
  background-color: var(--ion-color-danger);
}

.view-screen .postItem {
  margin-top: 0px;
}

.view-screen .postItem .postCredits {
  padding-top: 0;
}

.view-screen .postItem .postCredits ion-avatar {
  height: 34px;
  width: 34px;
  border: 1px solid rgba(255, 255, 255, .5);
  margin-top: 8px;
  display: inline-block;
  vertical-align: middle;
}

.view-screen .postItem .postCredits .postText {
  margin-top: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
}

.view-screen .postItem .postCredits .postText .post-text-line {
  line-height: 14px !important;
}

.view-screen .postItem .postCredits .initials, .commentsContent .postCredits .initials {
  font-size: 12px;
  height: 32px;
  width: 32px;
  margin: 0;
  padding: 0;
}
