.discover-screen {
  /*margin-bottom: 40px !important;*/
}

.discover-screen .top-user {
  padding: 0;
  margin: 0;
  --padding-start: 0px;
}
.discover-screen .top-users h2 {
  padding: 10px 10px;
}

.discover-screen .top-user ion-card {
  margin: 0;
  background-color: transparent;
  box-shadow: none;
  outline: none !important;
  border: none !important;
  border-radius: 0;
  width: 100% !important;
  --padding-start: 0px !important;
  --inner-padding-end: 0px;
}

.discover-screen .top-user .profileContainer {
  padding: 10px 0px;
}

.discover-screen .top-user .profileContainer .profileHeader {
  padding-left: 10px;
}

.discover-screen .top-user h1 {
  color: white;
}