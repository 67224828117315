.delete-button {
  background-color: #ef4227 !important;
  color: white !important;
  font-size: 200px;
}
.workshopScreen .hint-notice {
  margin: 20px 0 20px 0;
}
.workshopScreen ion-list {
  margin-top: 0px;
}
.workshopScreen ion-item {
  --padding-start: 0;
  border-bottom: 1px solid rgba(35, 50, 65, .5);
}
.workshopScreen ion-thumbnail {
  margin: 10px;
}
.workshopScreen ion-thumbnail img {
  border-radius: 10%;
}
.workshopScreen ion-thumbnail .thumbnailBlank {
  border-radius: 10%;
}
.no-builds-text {
  margin: 10px;
  text-align: center;
}
.no-builds-text p {
  text-align: center;
  width: 100%;
  font-size: 18px;
}
.no-builds-text p span {
  font-size: 16px;
  color: rgba(255, 255, 255, .5);
}

.workshop-header {
  margin-top: 20px !important;
  padding-left: 10px;
  padding-right: 10px;
}

.progressBar {
  margin-bottom: 10px;
}

.buildsTextContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.buildsTextLeft {
  font-weight: bold;
}

.buildsTextRight {
  font-size: 0.8em;
  color: var(--ion-color-medium);
}

.icon-text-container {
  display: flex;
  align-items: center;
}

.icon-text-container {
  display: flex;
  align-items: center;
}

.icon-container {
  height: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.bulb-icon {
  display: block; /* Ensures proper vertical alignment */
}

.text-content {
  margin-top: 2px; /* Adjust the margin between the icon and text as desired */
}