.post-page {
  margin-top: 10px !important;
  margin-bottom: 40px !important;
}

.parentPostItem, .hasParent {
  margin-left: 26px;
  border-left: 2px solid var(--ion-color-medium);
  border-radius: 0 !important;
}

.parentPostItem {
  border-radius: 0px;
  --padding-start: 0px !important;
  --inner-padding-end: 0px;
}

.parentPostItem .postMessage p {
  color: rgba(255, 255, 255, 1);
}

.add-comment {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid var(--ion-color-medium);
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.singlePostContent .postCredits {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.commentsContent .postCredits {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.parentPostItem .postCredits, .commentsContent .postCredits {
  padding-top: 6px;
  padding-bottom: 6px;
}

.singlePostContent .postCredits ion-avatar {
  height: 40px;
  width: 40px;
  border: 1.5px solid rgba(255, 255, 255, .5);
  margin-top: 6px;
}

.singlePostContent .postCredits .initials {
  font-size: 13px;
  height: 37px;
  width: 37px;
  margin: 0;
  padding: 0;
}

.parentPostItem .postCredits ion-avatar, .commentsContent .postCredits ion-avatar {
  height: 34px;
  width: 34px;
  border: 1px solid rgba(255, 255, 255, .5);
  margin-top: 6px;
}

.parentPostItem .postCredits .initials, .commentsContent .postCredits .initials {
  font-size: 12px;
  height: 32px;
  width: 32px;
  margin: 0;
  padding: 0;
}

.singlePostContent .postCredits .postText ion-text {
  font-size: 16px;
}

.parentPostItem .postCredits .postText ion-text, .commentsContent .postCredits .postText ion-text {
  font-size: 16px;
}

.singlePostContent .postCredits .postTimestamp:hover, .parentPostItem .postCredits .postTimestamp:hover, .commentsContent .postCredits .postTimestamp:hover {
  text-decoration: none;
}

.singlePostContent .postCredits .postText, .parentPostItem .postCredits .postText, .commentsContent .postCredits .postText {
  padding: 4px 0px;
}

.singlePostContent .postCredits .postText p, .parentPostItem .postCredits .postText p, .commentsContent .postCredits .postText p {
  margin: 0 !important;
  padding: 0 !important;
  line-height: 20px;
  color: #ffffff;
}

.parentPostItem .postCredits .postText p, .commentsContent .postCredits .postText p {
  line-height: 18px;
}

.postMessage, .postTitle {
  color: var(--ion-color-medium);
  margin-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  color: #ffffff;
}

#post-preview-canvas-div .overlayLabel {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  pointer-events: none;
}

.parentPostItem .postMessage, .commentsContent .postMessage {
  margin-top: 0;
  padding-bottom: 0;
}

.singlePostContent .postMessage p {
  font-size: 16px !important;
}

.parentPostItem .postMessage p, .commentsContent .postMessage p {
  font-size: 16px !important;
}

.parentPostItem .postDetails, .commentsContent .postDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: var(--ion-color-medium);
  margin-top: 0px;
  padding-bottom: 8px;
  width: 100%;
}

.custom-button {
  pointer-events: none;
  cursor: default;
}

.parentPostItem ion-item, .commentsContent ion-item {
  --padding-start: 0px;
}

.parentPostItem .commentItem ion-card, .commentsContent .commentItem ion-card {
  margin: 0;
  background-color: transparent;
  box-shadow: none;
  outline: none !important;
  border: none !important;
  border-radius: 0;
}

.parentPostItem ion-card-content, .commentsContent ion-card-content {
  padding: 0;
  outline: none !important;
}

.repliesContainer {
  border: none !important;
  outline: none !important;
}

.replyItem {
  border-bottom-color: none;
  --inner-border: none;
}

.repliesContainer {
  border-left: 2px solid var(--ion-color-medium) !important;
  margin-left: 26.5px;
}

.more-replies-button {
  margin-left: 0;
  color: var(--ion-color-medium);
}

.more-replies-button ion-label {
  padding-left: 26px;
}

.comment-group {
  border-bottom: 1px solid rgba(35, 50, 65, .5);
}

.add-comment-sign-in {
 border-bottom: 1px solid rgba(35, 50, 65, 1);
 border-top: 1px solid rgba(35, 50, 65, 1); 
 text-align: center;
 padding: 10px 0px;
}

.add-comment {
  transition: height 0.3s ease;
  overflow: hidden;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  position: relative;
  padding: 10px;
}

.add-comment.expanded {
  height: 190px;
}

.add-comment ion-textarea {
  --padding-top: 20px;
  --padding-bottom: 20px;
  margin: 10px !important;
  width: 100%;
  flex: 1;
}

.add-comment .character-count {
  text-align: right;
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.add-comment ion-button {
  margin-top: 8px;
  width: 100%;
  bottom: 0;
  position: static;
}

.commentItem, .replyItem {
  margin-top: 0;
  --inner-padding-end: 0px !important;
  border-image-width: 0 !important;
}

#newReplyModal .mediaSection {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

#newReplyModal .mediaBox {
  width: 100%;
  text-align: center;
  padding: 80px;
  background-color: rgba(0, 0, 0, .5);
}

#newReplyModal .addMediaButton {
  display: inline-block;
  font-size: 16px;
}

#newReplyModal .messageInput {
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  padding: 8px 20px;
}

#newReplyModal .messageDetails {
  border-top: 1px solid rgb(35, 50, 65);
  margin: 8px 20px;
}

#newReplyModal .postButton {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 0;
  --padding-bottom: 0;
}

.commentOptionPopover ion-list {
  padding: 0;
}

.parentPostItem ion-item {
  padding: 0;
  margin: 0;
  background-color: transparent;
  box-shadow: none;
  outline: none !important;
  border: none !important;
}

.parentPostItem ion-card {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.parentPostItem ion-card-content .postDetails {
  border: none !important;
  outline: none !important;
  margin: 0;
  padding: 0;
  padding-bottom: 8px;
}

.parentPostItem ion-router-link:hover {
  text-decoration: underline;
  color: var(--ion-color-medium);
}

.deleted-post .display-name, .deleted-post .postMessage p {
  color: var(--ion-color-medium);
}

.more-comments {
  margin: 0;
  padding: 0;
  color: var(--ion-color-medium);
  border-left: 2px dashed var(--ion-color-medium);
  margin-left: 26px;
  display: flex;
  align-items: center;
  height: auto !important;
  justify-content: center;
  --padding-start: 0px !important;
  --inner-padding-end: 0px;
  border-radius: 0 !important;
}

.more-comments p {
  width: 100%;
  padding-left: 10px;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto !important;
}

.postTitle h1, .postTitle h2 {
  font-weight: bold !important;
}

.post-page .postMessage h2 {
  font-size: 20px !important;
  font-weight: bold;
}

.deleted-post .postMessage h1 {
  color: rgba(255, 255, 255, 1);
}

.post-preview-canvas-div-div {
  overflow: hidden;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px; /* Rounded corners */
  transform: translate3d(0,0,0);
}

#post-preview-canvas-div {
  position: relative;
  width: 100%;
  padding-bottom: 75%; /* 4:3 aspect ratio (300 / 400 * 100%) */
  max-width: 100%;
  overflow: hidden;
  background-color: #d8ebf2;
}

.post-preview-canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 5px; /* Rounded corners */
}

