.profile-page {
  margin-top: 10px !important;
  margin-bottom: 40px !important;
}

.no-posts-text {
  margin: 10px;
  text-align: center;
}
.no-posts-text p {
  text-align: center;
  width: 100%;
  font-size: 18px;
}
.no-posts-text p span {
  font-size: 16px;
  color: rgba(255, 255, 255, .5);
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center; /* Add this line */
}

.iconText {
  margin-left: 10px;
}

.profileContainer {
  display: grid;
  grid-template-columns: 1fr; /* Change from auto auto to 1fr */
  column-gap: 10px;
  border-bottom: 1px solid rgb(35, 50, 65);
  padding-bottom: 10px;
  padding: 10px;
}

ion-modal .profilePicture ion-avatar {
  margin: 20px auto;
  margin-bottom: 0;
}

ion-modal .username {
  margin-top: 10px;
  text-align: center;
}

.profileButtonContainer {
  display: flex;
  justify-content: left;
  margin-top: 10px;
}

.editProfileButton, .followProfileButton {
  min-width: 120px;
}

.userDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.userDetails > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Added flex-wrap property */
  margin-top: 10px;
}

.username,
.bio,
.createdAt {
  margin-bottom: 8px;
  flex-basis: 100%; /* Set the flex-basis to 100% to occupy full width */
}

.iconText {
  margin-right: 10px; /* Add spacing between icon and text */
}

.bio {
  margin-top: 4px;
  margin-bottom: 3px;
}

.userPosts {
  padding: 0;
  margin: 0;
}

.userPosts ion-item {
  border: none !important;
  outline: none !important;
  margin: 0;
  padding: 0;
  --padding-start: 0px !important;
  --inner-padding-end: 0px;
}

.postItem {
  background: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-page .postItem {
  border-bottom: 1px solid rgba(35, 50, 65, .5);
}

.postItem ion-card-content {
  padding: 0px;
  width: 100%;
}

.postText {
  margin-left: 10px;
  margin-top: 0;
  padding: 4px 0px;
}

.postText p {
  margin: 0 !important;
  padding: 0 !important;
  line-height: 20px !important;
}

.postImage {
  padding: 0px 10px;
  position: relative;
}

.postImage img {
  border-radius: 5px;
}

.postImage .overlayLabel {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
}

.postMessage p {
  font-size: 16px !important;
}

.postMessage a {
  text-decoration: none;
}

.postMessage a:hover {
  text-decoration: underline;
}

.postTitle h2 {  
  font-size: 20px !important;
  font-weight: bold;
}

.postTitle {
  padding: 0px 10px;
  color: #ffffff;
  color: var(--ion-color-medium);
  margin-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  color: #ffffff;
}

.profile-page ion-chip {
  font-size: 12px;
  margin-left: -2px;
  margin-right: 10px;
}

.postContent {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  box-sizing: border-box; /* Add this line */
}

.postDetails {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--ion-color-medium);
  padding-bottom: 8px;
  width: 100%;
}

.postDetails ion-icon {
  margin-right: 4px;
  font-size: 18px;
}

.postDetails .buttonsLeft {
  display: flex;
  align-items: center;
}

.postDetails .buttonContainer {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}

.ellipsisButtonContainer ion-button, .postDetails .buttonContainer ion-button {
  --padding-start: 0;
  --padding-end: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  background: transparent;
  border: none;
  padding: 0;
}

.ellipsisButtonContainer ion-button ion-icon, .postDetails .buttonContainer ion-button ion-icon {
  width: 1.25rem; /* Adjust the size as needed */
  height: 1.25rem; /* Adjust the size as needed */
  margin-right: 0.075rem; /* Adjust the value to eliminate the empty space */
  padding: 5px;
}

.showMore:hover {
  text-decoration: underline;
}

.postTimestamp:hover {
  text-decoration: underline;
}

.postItem p {
  color: #ffffff;
}

.userPosts ion-item {
  --padding-start: 0;
}

.postContent ion-text {
  font-size: 16px;
}

.showMore {
  padding-top: 10px;
  font-size: 16px !important;
  color: var(--ion-color-primary) !important;
  cursor: pointer;
}

.postContent ion-avatar {
  height: 40px;
  width: 40px;
  border: 1.5px solid rgba(255, 255, 255, .5);
  margin-top: 6px;
}

.postContent .initials {
  font-size: 13px;
  height: 37px;
  width: 37px;
  margin: 0;
  padding: 0;
}

.postContent {
  display: flex;
  align-items: center;
}

.ellipsisButtonContainer {
  margin-left: auto;
}

.postDetails {
  display: flex;
  align-items: center;
}

.buttonsRight {
  margin-left: auto;
}

.singlePostContent {
  width: 100%;
}

