:root {
  --ion-background-color: #121d26;
  --ion-background-color-rgb: 18,29,38;

  --ion-card-background: var(--ion-color-step-50);

  --ion-text-color: #eff3f4;
  --ion-text-color-rgb: 239,243,244;

  --ion-color-step-50: #1d2830;
  --ion-color-step-100: #28323b;
  --ion-color-step-150: #333d45;
  --ion-color-step-200: #3e484f;
  --ion-color-step-250: #49535a;
  --ion-color-step-300: #545d64;
  --ion-color-step-350: #5f686e;
  --ion-color-step-400: #6a7378;
  --ion-color-step-450: #757d83;
  --ion-color-step-500: #81888d;
  --ion-color-step-550: #8c9397;
  --ion-color-step-600: #979da2;
  --ion-color-step-650: #a2a8ac;
  --ion-color-step-700: #adb3b6;
  --ion-color-step-750: #b8bec1;
  --ion-color-step-800: #c3c8cb;
  --ion-color-step-850: #ced3d5;
  --ion-color-step-900: #d9dedf;
  --ion-color-step-950: #e4e8ea;

  --ion-color-bg: #121d26;
  --ion-color-panel: #1d2c38;
  --ion-color-headline: #eff3f4;
  --ion-color-text: #dae3e5;
  --ion-color-subtitle: #7a93a0;
  --ion-color-button: #ffca34;
  --ion-color-other-button: #1da7cc;
  --ion-color-cancel-button: #f47739;

  --ion-color-primary: #ffca34;
  --ion-color-primary-rgb: 255,202,52;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #e0b22e;
  --ion-color-primary-tint: #ffcf48;

  --ion-color-secondary: #1da7cc;
  --ion-color-secondary-rgb: 29,167,204;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #1a93b4;
  --ion-color-secondary-tint: #34b0d1;

  --ion-color-tertiary: #1483af;
  --ion-color-tertiary-rgb: 20,131,175;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #12739a;
  --ion-color-tertiary-tint: #2c8fb7;

  --ion-color-success: #5bbb62;
  --ion-color-success-rgb: 91,187,98;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #50a556;
  --ion-color-success-tint: #6bc272;

  --ion-color-warning: #f47739;
  --ion-color-warning-rgb: 244,119,57;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d76932;
  --ion-color-warning-tint: #f5854d;

  --ion-color-danger: #ef4227;
  --ion-color-danger-rgb: 239,66,39;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d23a22;
  --ion-color-danger-tint: #f1553d;

  --ion-color-medium: #536b7a;
  --ion-color-medium-rgb: 83,107,122;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #495e6b;
  --ion-color-medium-tint: #647a87;

  --ion-color-light: #e4e8e9;
  --ion-color-light-rgb: 228,232,233;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #c9cccd;
  --ion-color-light-tint: #e7eaeb;

}