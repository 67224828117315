.notifications-screen {
  /*margin-bottom: 40px !important;*/
}

.notifications-screen .loadingSpinner {
  margin-top: 10px !important;
}

.notifications-screen .no-notifications {
  margin-top: 40px;
  text-align: center;
}

.no-notifications p {
  text-align: center;
  width: 100%;
  font-size: 18px;
}
.no-notifications p span {
  font-size: 16px;
  color: rgba(255, 255, 255, .5);
}

.notifications-screen h2 {
  padding: 10px 10px;
}

.notifications-screen p {
  padding: 0px 10px;
}

.notifications-screen ion-item {
  border-bottom: 1px solid rgba(35, 50, 65, .5);
}