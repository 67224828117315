/* For desktop devices with a screen width greater than 768px */
@media (min-width: 768px) {
  .limitedWidth {
    max-width: 800px;
    margin: 0 auto;
  }
}
.preventSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.app-spinner {
  margin-top: 100px;
}

.hoverUnderline, a.hoverUnderline {
  color: var(--ion-color-medium) !important;
}

.hoverUnderline:hover, a.hoverUnderline:hover {
  text-decoration: underline !important;
}

.app-header ion-avatar {
  margin: 0;
}

ion-fab.main-app-action-button {
  height: 56px;
  position: relative;
}

ion-fab.main-app-action-button ion-fab-button {
  --background: var(--ion-color-primary);
  --border-radius: 2px;
}

ion-infinite-scroll-content {
  --color: var(--ion-color-primary);
}

ion-tab-button {
  --min-height: 40px;
  --max-height: 40px;
  --padding-top: 5px;
  --padding-bottom: 5px;
}
ion-tab-bar {
  display: auto;
}

ion-tab-bar .notifications-badge {
  position: absolute;
  top: -4px;
  font-size: 10px;
  min-width: 16px;
  height: 16px;
  padding: 2.5px;
}

ion-button{
  text-transform:none!important;
}
.newButton ion-icon {
  width: 40px;
  height: 40px;
  position: relative;
  background-color: var(--ion-color-primary);
  color: rgb(51, 61, 69);
  border-radius: 10%;
  padding: 2px;
  margin: 0;
}
ion-avatar {
  height: 70px;
  width: 70px;
  border: 2px solid rgba(255, 255, 255, .5);
}
.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  color: white;
}
.profileText {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 20px;
}
.profileText h1 {
  margin-top: 0;
  margin-bottom: 0;
}
.username {
  margin-top: -2px;
}
.sideMenu .profileHeader {
  margin-top: 10px;
  margin-bottom: 6px;
}
.sideMenu ion-avatar {
  height: 40px;
  width: 40px;
  margin: 10px;
}
.sideMenu .initials {
  font-size: 12px;
  height: 35px;
  width: 35px;
  margin: 0;
  padding: 0;
}
.sideMenu .profileText {
  margin: 0;
}
.sideMenu .profileText h1 {
  font-size: 20px;
}
.sideMenu .profileText .username {
  font-size: 14px;
}
.sideMenu ion-list {
  margin: 0;
  padding: 0;
}
.sideMenu ion-item {
  --padding-start: 0;
}

.sideMenu .disclaimer p {
  text-align: left;
  font-size: 12px;
  color: rgba(255, 255, 255, .5);
  margin: 0 !important;
  padding: 0 !important;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.sideMenu .disclaimer p span {
  margin-top: 4px !important;
}

.divider {
  border: none;
  border-top: 1px solid rgb(35, 50, 65);
  margin: 10px 0;
}

.secondary-list .menu-item {
  --ion-item-padding-start: 16px;
  --ion-item-padding-end: 16px;
  font-size: 14px;
}

#newBuildModal .buildNameInput, #newPostModal .postTitleInput, #newPostModal .postTextInput  {
  width: 100%;
  font-size: 16px;
  margin-top: 10px; /* Add margin to separate from mediaSection */
  padding: 3px 20px;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0;
  --padding-bottom: 0;
}

#newPostModal .postTextInput {
  margin-top: 0;
}

#newBuildModal .buildButton {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 0;
  --padding-bottom: 0;
}
#attachBuildModal ion-list {
  margin: 0 !important;
  padding: 0 !important;
}
#attachBuildModal ion-item {
  --padding-start: 0;
  border-bottom: 1px solid rgba(35, 50, 65, .5);
}
#attachBuildModal ion-thumbnail {
  margin: 10px;
}
#attachBuildModal ion-thumbnail img {
  border-radius: 10%;
}
#attachBuildModal ion-thumbnail .thumbnailBlank {
  border-radius: 10%;
}

#newPostModal .mediaSection {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

#newPostModal .mediaBox {
  width: 100%;
  text-align: center;
  padding: 80px;
  background-color: rgba(0, 0, 0, .5);
}

#newPostModal .addMediaButton {
  display: inline-block;
  font-size: 16px;
}

#newPostModal .messageDetails {
  /*border-top: 1px solid rgb(35, 50, 65);*/
  margin: 0px 20px;
  padding: 0;
}
#newPostModal .attachmentDetails {
  margin: 0px 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#newPostModal .attachmentDetails .attachment-container {
  margin-top: 30px;
  width: 300px;
  height: 225px; /* 4:3 aspect ratio */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--ion-color-medium);
  border-radius: 5px;
}

#newPostModal .attachmentDetails .capturedImage {
  margin-top: 30px;
  width: 300px;
  height: 225px; /* 4:3 aspect ratio */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative; /* Added */
}

#newPostModal .attachmentDetails .capturedImage img {
  border-radius: 5px;
}

#newPostModal .attachmentDetails .removeButton {
  --padding-start: 4px;
  --padding-end: 4px;
  --padding-top: 8px;
  --padding-bottom: 8px;
  margin-top: 36px;
  margin-right: 6px;
}

#newPostModal .attachmentDetails .toggleOverlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  gap: 4px;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  color: white;
  margin-top: 6px;
  margin-left: 6px;
}

#newPostModal .attachmentDetails .toggleButton {
  padding: 4px 8px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 2px;
  font-size: 12px;
}

#newPostModal .attachmentDetails .toggleButton.active {
  background-color: rgba(255, 255, 255, 0.3);
}

#newPostModal .postButton {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 0;
  --padding-bottom: 0;
}

.auto-width-toast {
  width: auto !important;
  min-width: 250px; /* Set a minimum width if desired */
  max-width: 80%; /* Set a maximum width if desired */
  left: 50%;
  transform: translateX(-50%);
}

ion-popover {
  --width: 250px;
}

.postSharePopover ion-list, .postDeletePopover ion-list {
  padding: 0;
}

.sponsorship-page .subtext {
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 20px;
  /*color: var(--ion-color-medium);*/
}

.sponsorship-page .gif-container {
  display: flex;
  justify-content: center;
}

.sponsorship-page .gif-container img {
  max-width: 100%;
  height: auto;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fade-in 0.35s;
  animation-delay: 0.15s;
  animation-fill-mode: forwards;
}

#captureBuildModal ion-content {
  --background: #c3d7de;
  width: 100%;
  height: 100%;
}

.build-preview-canvas-div-div {
  overflow: hidden;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  /*transform: translate3d(0,0,0);*/
  max-width: 100%;
  max-height: 100%;
}

.build-preview-canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 0px; /* Rounded corners */
  background-color: #c3d7de;
}

.build-preview-viewfinder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 0px; /* Rounded corners */
  pointer-events: none;
}

.build-preview-viewfinder .info-text {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  max-width: 100%;
  height: 0; /* Setting height to 0 */
  padding-bottom: 75%; /* Aspect ratio placeholder */
  overflow: hidden;
  background-color: rgba(0, 0, 0, .0);
}

.build-preview-viewfinder .info-text .zoom-level {
  position: absolute;
  top: 3.33%;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 1.0);
  margin-top: -5px;
  font-size: 10px;
  line-height: 10px;
  font-weight: bold;
}

.left-buttons {
  pointer-events: auto;
  position: absolute;
  top: 50%;
  left: 3.33%;
  margin-left: -10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-buttons {
  pointer-events: auto;
  position: absolute;
  top: 50%;
  right: 3.33%;
  margin-right: -10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-buttons button {
  width: 40px;
  height: 40px;
  background-color: #f5fafc;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 50%;
}

.right-buttons button {
  width: 40px;
  height: 40px;
  background-color: #f5fafc;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 50%;
}

.left-buttons button:hover,
.right-buttons button:hover {
  background-color: white;
}

.camera-controls button.move-up,
.camera-controls button.move-down,
.camera-controls button.move-left,
.camera-controls button.move-right {
  margin: 4px 0px;
  border: 2px solid var(--ion-color-medium);
}

.camera-controls ion-icon {
  color: red;
}

.camera-controls button.move-down .icon-small {
  margin-top: 2px;
}

.camera-controls button.move-left .icon-small {
  margin-right: 2px;
}

.camera-controls button.move-right .icon-small {
  margin-left: 2px;
}

.build-preview-viewfinder img {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.capture-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 0px; /* Rounded corners */
}

.capture-overlay button:hover {
  background-color: red;
}

.new-build-illustration {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}