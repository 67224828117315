@media (min-width: 768px) {
  ion-modal {
    --border-radius: 8px;
  }
}
.center-circle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  /* Set the circle color to white to allow color inversion */
  background-color: #ffffff;
  /* Use blend mode to invert the color behind the circle */
  mix-blend-mode: difference;
  /* Add a pseudo-element to create the "hole" in the center of the circle */
  &::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    /* Set the pseudo-element background color to black to allow color inversion */
    background-color: #000000;
  }
}

.undo-toolbar {
  position: absolute;
  --background: rgba(0, 0, 0, 0);
  top: 44px;
  pointer-events: none;
}
.undo-toolbar ion-button {
  pointer-events: auto;
}
.rotation-toolbar {
  position: fixed;
  --background: rgba(0, 0, 0, 0);
  bottom: 120px;
 
}
.rotation-toolbar ion-button {
 
}
.pokkitModal ion-title {
  border-bottom: 1px solid #445a66;
}
.pokkitModal .objectOptions ion-grid {
  margin: 0;
  padding: 0;
}

.pokkitModal .objectOptions ion-grid ion-card {
  margin: 0;
  padding: 0;
  border: none;
}

ion-col div.shapeButtons {
  border-radius: 5%;
}
ion-col div {
  border: 2px solid transparent;
}
.objectOptions ion-col div:hover {
  border: 2px solid white;
  cursor: pointer;
  transform: scale(1.1);
}
ion-col div.glossy {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5));
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.01), 0 10px 20px rgba(0, 0, 0, 0.01), 0 15px 25px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 20px rgba(255, 255, 255, 0.1), inset 0 0 30px rgba(255, 255, 255, 0.1);
}
ion-col div.glass {
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0) 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.3));
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.3), inset 0 0 20px rgba(255, 255, 255, 0.6), inset 0 0 30px rgba(255, 255, 255, 0.6), 0 2px 3px rgba(0, 0, 0, 0.3);
}
ion-modal ion-content ion-card {
  /*background: transparent;*/
  box-shadow: none;
  margin-top: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid rgba(35, 50, 65, 1) !important;
}
ion-modal ion-content ion-card-header {
  padding: 6px;
}
.catTitle {
  font-size: 16px;
  padding-top: 2px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: white;
  border-bottom: 1px solid rgba(35, 50, 65, 1) !important;
}
.inventoryFooter {
  max-width: 800px;
  margin: 0 auto;
}
ion-modal ion-content ion-grid svg {
  stroke: #000000;
  stroke-width: 1px;
  stroke-linejoin: round;
  stroke-linecap: butt;
}
.inventoryModal svg {
  stroke: #000000;
  stroke-width: 1px;
  stroke-linejoin: round;
  stroke-linecap: butt;
}

.inventoryFooter svg {
  max-height: 60px;
  padding-bottom: 10px;
  stroke: #000000;
  stroke-width: 1px;
  stroke-linejoin: round;
  stroke-linecap: butt;
}
@keyframes changeEffect {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(.1);
  }
  50% {
    transform: scale(1.05);
  }
  75% {
    transform: scale(.95);
  }
  100% {
    transform: scale(1);
  }
}
.svgAnimation {
  animation: changeEffect 0.4s cubic-bezier(.17,.67,.83,.67) forwards;
}

/* Offline / live overlay */

.live-switch-overlay {
  /* Center the toggle horizontally */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align to the top */
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50px;
  left: 0;
  pointer-events: none;
}

.live-switch-overlay .toggle-container {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 2px; /* Adjust padding for better spacing */
  pointer-events: auto;
}

.live-switch-overlay .toggleButton.active.offline {
  background-color: rgba(255, 255, 255, .3); /* Use a darker color for the active "Offline" button */
  border-radius: 4px; /* Add slight border radius for the active button */
}

.live-switch-overlay .toggleButton.active.live {
  background-color: var(--ion-color-danger); /* Use the "danger" color for the active "Live" button */
  border-radius: 4px; /* Add slight border radius for the active button */
}

.live-switch-overlay .toggleButton {
  background-color: transparent;
  color: white; /* Set text color to white */
  font-weight: bold; /* Make the text bold */
  font-size: 12px; /* Decrease the font size slightly */
  padding: 6px 12px; /* Decrease the padding slightly */
  cursor: pointer;
  border: none;
}

.rotation-toolbar {
  pointer-events: none;
}

.rotation-toolbar ion-button {
  pointer-events: auto;
}

/* Perspective toggle overlay */

.perspective-toggle-overlay {
  /* Center the toggle horizontally */
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align to the top */
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 105px;
  left: 0;
  pointer-events: none;
}

.perspective-toggle-overlay .toggle-container {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 2px; /* Adjust padding for better spacing */
  pointer-events: auto;
}

.perspective-toggle-overlay .perspective-toggleButton.active.orbit {
  background-color: rgba(255, 255, 255, .3); /* Use a darker color for the active "Offline" button */
  border-radius: 4px; /* Add slight border radius for the active button */
}

.perspective-toggle-overlay .perspective-toggleButton.active.firstPerson {
  background-color: rgba(255, 255, 255, .3); /* Use the "danger" color for the active "Live" button */
  border-radius: 4px; /* Add slight border radius for the active button */
}

.perspective-toggle-overlay .perspective-toggleButton {
  background-color: transparent;
  color: white; /* Set text color to white */
  font-weight: bold; /* Make the text bold */
  font-size: 16px; /* Decrease the font size slightly */
  padding: 4px 8px 2px 8px; /* Decrease the padding slightly */
  cursor: pointer;
  border: none;
}

/* Create Screen Loading */

.create-screen .editor-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.75); /* Use rgba() to set the opacity of the background */
}

/* Create Screen Rotation Controls */

.create-screen .rotation-controls {
  position: fixed;
  bottom: 100px;
  right: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  z-index: 1;
}

.create-screen .rotation-controls .rotation-indicator {
  --background: rgba(0, 0, 0, .5);
  --box-shadow: none;
  --color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 60px;
  left: 50%; /* Place it at the center horizontally */
  transform: translateX(-50%); /* Center it with the main-button */
  transition: transform 0.3s ease;
  background-color: rgba(255, 255, 255, .5);
}

.create-screen .rotation-controls .main-button {
  --background: rgba(0, 0, 0, .5);
  --box-shadow: none;
  --color: white;
  width: 48px;
  height: 48px;
  --border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease;
}

.create-screen .rotation-controls .main-button:hover {
  --background-hover: rgba(0, 0, 0, 1);
  --background-activated: rgba(0, 0, 0, 1);
  --background-focused: rgba(0, 0, 0, 1);
}

.create-screen .rotation-controls .main-button.active {
  transform: rotate(45deg);
}

.create-screen .rotation-controls .button-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 4px;
  margin-bottom: -8px;
}

.create-screen .rotation-controls .button-list .circle-button {
  margin-bottom: 0px;
}

.create-screen .rotation-controls .circle-button {
  --background: rgba(0, 0, 0, .5);
  --background-activated: rgba(0, 0, 0, 1);
  --background-hover: rgba(0, 0, 0, 1);
  --box-shadow: none;
  --color: white;
  width: 40px;
  height: 40px;
  --border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.create-screen .rotation-controls .side-buttons {
  position: absolute;
  bottom: 0;
  left: -90px; /* Adjust this value as needed to position the buttons */
  display: flex;
  flex-direction: row; /* Change to "row" to place buttons horizontally */
  gap: 0px;
  margin-bottom: 3px;
}

/* Create Screen Movement Controls */

.create-screen .vertical-movement-controls {
  position: fixed;
  top: 50%; /* Position the element at the middle vertically */
  right: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  z-index: 1;
  transform: translateY(-50%); /* Center the element vertically */
}

.create-screen .vertical-movement-controls .button-list .circle-button {
  margin-bottom: 0px;
}

.create-screen .vertical-movement-controls .circle-button {
  --background: rgba(0, 0, 0, .5);
  --background-activated: rgba(0, 0, 0, 1);
  --background-hover: rgba(0, 0, 0, 1);
  --box-shadow: none;
  --color: white;
  width: 40px;
  height: 40px;
  --border-radius: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}


.create-screen .movement-controls {
  position: fixed;
  bottom: 94px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  z-index: 1;
}

.create-screen .movement-controls.active {
  bottom: 100px;
  left: 40px;
}

.create-screen .movement-controls .orientation-indicator {
  --background: rgba(0, 0, 0, .5);
  --box-shadow: none;
  --color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 0; /* Place it at the center horizontally */
  transform: translateX(-50%); /* Center it with the main-button */
  transition: transform 0.3s ease;
  background-color: rgba(255, 255, 255, .5);
  font-size: 12px;
  font-weight: bold;
  color: black;
  pointer-events: none;
}

.create-screen .movement-controls .orientation-indicator.active {
  bottom: 48px;
  left: 50%;
}

.create-screen .movement-controls .main-button {
  --background: rgba(0, 0, 0, .0);
  --box-shadow: none;
  --color: white;
  width: 16px;
  height: 16px;
  --border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease;
}

.create-screen .movement-controls .main-button:hover {
  --background-hover: rgba(0, 0, 0, 1);
  --background-activated: rgba(0, 0, 0, 1);
  --background-focused: rgba(0, 0, 0, 1);
}

.create-screen .movement-controls .main-button.active {
  transform: rotate(45deg);
}

.create-screen .movement-controls .top-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
  margin-bottom: 0px;
}
.create-screen .movement-controls .bottom-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
  margin-bottom: 0px;
}

.create-screen .movement-controls .button-list .circle-button {
  margin-bottom: 0px;
}

.create-screen .movement-controls .circle-button {
  --background: rgba(0, 0, 0, .5);
  --background-activated: rgba(0, 0, 0, 1);
  --background-hover: rgba(0, 0, 0, 1);
  --box-shadow: none;
  --color: white;
  width: 40px;
  height: 40px;
  --border-radius: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.create-screen .movement-controls .left-buttons {
  position: absolute;
  bottom: 0;
  left: -26px; /* Adjust this value as needed to position the buttons */
  display: flex;
  flex-direction: row; /* Change to "row" to place buttons horizontally */
  gap: 0px;
  margin-bottom: 3px;
}

.create-screen .movement-controls .right-buttons {
  position: absolute;
  bottom: 0;
  right: -26px; /* Adjust this value as needed to position the buttons */
  display: flex;
  flex-direction: row; /* Change to "row" to place buttons horizontally */
  gap: 0px;
  margin-bottom: 3px;
}