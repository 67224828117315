.home-screen {
  /*margin-bottom: 40px !important;*/
}

.home-screen .loadingSpinner {
  margin-top: 10px !important;
}

.home-screen .no-posts {
  margin-top: 40px;
  text-align: center;
}

.no-posts p {
  text-align: center;
  width: 100%;
  font-size: 18px;
}
.no-posts p span {
  font-size: 16px;
  color: rgba(255, 255, 255, .5);
}

.home-screen .postItem {
  border-bottom: 1px solid rgba(35, 50, 65, .5);
}

.home-screen .feed-post {
  border-radius: 0px;
  --padding-start: 0px !important;
  --inner-padding-end: 0px;
}

a.post-item-link {
  text-decoration: none !important;
}