.center-logo {
  vertical-align: middle;
  height: 44px;
  width: auto;
}
ion-header ion-avatar {
  height: 30px;
  width: 30px;
  margin: 10px;
}
ion-header ion-avatar .initials {
  font-size: 9px;
  height: 26px;
  width: 26px;
  margin: 0;
  padding: 0;
}